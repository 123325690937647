





















































.navbar-dark .navbar-nav .dropdown-menu .dropdown-item {
  color: #000;
  font-weight: normal;
}
