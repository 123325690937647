abbr,
dfn {
  font-style: normal;
}

footer {
  p {
    text-align: left !important;
  }
}

// Nested grid
.row .col .row .col {
  li,
  p {
    hyphens: auto;
  }

  p {
    text-align: justify;
  }
}


.lang-flag-icon {
  width: 1.3333em;
}

.carousel-indicators {
  z-index: 5;
}

ol.carousel-indicators li:focus {
  outline: none !important;
}

.w-max-content {
  max-width: 100%;
  width: max-content !important;
}

@include media-breakpoint-up(lg) {

  div.carousel-item {
    min-height: 260px !important;
  }

  .navbar {
    ul.dropdown-menu {
      width: max-content;
      max-width: 30rem;

      & li:hover {
        background-color: #f8f9fa;
      }
    }
    a.nav-link[aria-expanded="true"] {
      background-color: rgb(255, 255, 255);
      border-top-left-radius: 3px;
      color: black !important;
      z-index: 1001;
    }
    ul.dropdown-menu.show {
      margin-top: 0;
      border-left: none;
      border-top: none;
      border-top-left-radius: 0;
      box-shadow: -23px 17px 29px -23px rgba(0,0,0,0.31);
    }
  }

  .sidebar-border {
    border-image: linear-gradient(to top, rgba(255, 79, 0, 0), rgba(255, 79, 0, .7)) 30;
    border-right: 1px solid;
    border-width: 0 1px 0 0;
  }

  .sidebar-border--no-gradient {
    border-right: 1px solid rgba(255, 79, 0, .7);
  }
}

@include media-breakpoint-down(md) {

  #language-dropdown a.nav-link.dropdown-toggle {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .credits {
    opacity: .9 !important;
  }

  dd,
  dt,
  p {
    hyphens: auto;
    text-align: justify;
    text-justify: auto;
  }

  div.carousel-item {
    min-height: 310px !important;
  }

  div[__typename="CardsRow"] .card-img {
    max-height: 165px;
  }

  dt {
    font-weight: 400;
  }

  h2.text-center {
    text-align: left !important;
  }

  .navbar {
    .background-filter {
      background-color: $dark;
      left: 0;
      min-height: 100vh;
      min-width: 100vw;
      opacity: .7;
      position: fixed;
      top: 0;
      transition: opacity 2s;
      transform: translateY(0);
      z-index: 15;

      &--hidden {
        opacity: 0;
        transition: opacity .3s, transform 0s .3s;
        z-index: -100;
        transform: translateY(-100vh);
      }
    }

    .contact-button-group {
      flex-direction: column;

      a.btn {
        border-radius: 0;
      }
    }

    .burger-icon {
      background-color: #fff;
      height: 2px;
      margin-bottom: 5px;
      transition: .35s ease-in-out 0.1s;
      width: 25px;

      &__bottom {
        margin-bottom: 0;
      }
    }

    .navbar-collapse {
      background-color: $primary;
      height: 100%;
      left: -100%;
      max-width: 250px;
      min-height: 100vh;
      overflow-y: scroll;
      padding-right: 0 !important;
      position: fixed;
      top: 0px;
      transition: left .45s ease-in-out;

      width: 60vw;
      z-index: 100;

      &.show {
        left: 0;
      }

      a.dropdown-item,
      a.nav-link {
        outline: none;
        padding: 1rem .5rem 1rem 1rem !important;
        white-space: normal;
      }

      .navbar-nav .dropdown-menu {
        background-color: transparent;
        border: none;
        box-shadow: none;
        margin-top: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        .dropdown-divider {
          display: none;
        }

        .dropdown-item {
          border-top: 1px solid $dark;
          color: #FFF;
          margin-top: 0;
          padding: 1rem .5rem 1rem 2rem !important;
        }

        .dropdown-item:hover {
          background: rgb(230, 106, 48);
        }
      }

      .nav-item {
        border-top: 1px solid $dark;

        &:hover {
          background: rgb(230, 106, 48);
        }

        &.dropdown.show:hover {
          background: $primary;
        }
      }

    }

    .navbar-toggler {
      background: transparent;
      border: none;
      outline: none;
      position: absolute;
      right: 1rem;
      z-index: 100;
    }

    .navbar-toggler[aria-expanded="true"] .burger-icon {

      &__bottom {
        transform-origin: bottom left;
        transform: rotate(-45deg) translateX(-2px) translateY(0px);
      }

      &__middle {
        transform: scale(0) translateX(-5px);
      }

      &__top {
        transform-origin: top left;
        transform: rotate(45deg) translateX(-2px);
      }
    }
  }

  .sidebar-heading {
    margin-bottom: 1.2rem !important;
    text-align: left !important;
  }

  .slide__inner-container {
    flex-basis: 95% !important;
  }
}

@include media-breakpoint-only(xs) {
  footer {
    hyphens: auto;
  }
}
