











































































.container-fluid {
  background-color: #FF4F00;

  .credits {
    opacity: .25;
    transition: opacity .4s ease-out, transform .8s ease-out;

    &:hover {
      opacity: 1;
      text-decoration: none;

      .heart {
        display: inline-block;
        animation: .5s ease-in-out infinite alternate pulse;
      }
    }

    img {
      height: 1.3rem;
      vertical-align: text-bottom;
    }
  }
}

@keyframes pulse {
  from { transform: scale(1); }
  to { transform: scale(1.5); }
}
