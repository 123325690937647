
































































div.carousel-item {
  height: 40vh;
  min-height: 5rem;
  max-height: 400px;
}

.slide {
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;

  &__inner-container {
    display: flex;
    flex: 0 0 70%;
    flex-direction: column;
    justify-content: center;
    padding: .1rem .2rem;
  }

  &__heading {
    color: #FFF;

    span {
      font-size: .8em;
    }
  }

  &__button {
    font-size: 2rem;
  }
}
