

















































































































































































































.cta--danger {
 background-color: rgb(185, 0, 50);
 border-color: rgb(185, 0, 50);

  &:hover, :focus {
    background-color: rgb(150, 0, 40);
    border-color: rgb(150, 0, 40);
  }
}

#language-dropdown::v-deep ul.dropdown-menu.show {
  min-width: 8.5rem !important;

  a {
    align-items: center;
    display: flex;
    padding: 0.25rem 1.5rem 0.25rem .5rem;
  }
}

.navbar-dark::v-deep .navbar-nav .nav-link {
  color: #FFF;
  font-weight: bold;
}
