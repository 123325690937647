





































































































div[__typename="CardsRow"] {

  .card .card-body ul {
    max-width: 38rem;
    padding-left: 0;
    text-align: justify;

    li {
      border-left: 1px solid #ff4f00 !important;
      list-style: none !important;
      margin-bottom: 1.5rem !important;
      padding: 0 0 0 .6rem !important;
    }
  }

  .card-body {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-top: none !important;
  }

  .card-header {
    border-bottom: none;
  }

  .card-img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    object-fit: cover;
  }
}
