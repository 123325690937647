



































































































































































































































.container-fluid {
  background-color: rgba(63, 63, 63, .9);

  img.one-percent-for-the-planet-logo {
    max-width: 100%;
    width: 7rem;
  }
}

.language-nav a.nav-link {
  align-items: center;
  display: flex;
}
